import api from '@/request/api'
import { IPageRes } from "@/apis/page";
import { ISystemAccount, ISystemAccountQuery } from "@/apis/systemManage/account/types";

/**
 * 分页查询
 * @param params 查询参数
 */
export const systemAccountQueryApi = (params: ISystemAccountQuery) => api.get<IPageRes<ISystemAccount[]>>("/admin/systemAdmin/query", params)

/**
 * 创建
 * @param params 查询参数
 */
export const systemAccountCreateApi = (params: ISystemAccount) => api.postJ("/admin/systemAdmin/create", params)

/**
 * 修改
 * @param params 查询参数
 */
export const systemAccountModifyApi = (params: ISystemAccount) => api.postJ("/admin/systemAdmin/modify", params)


/**
 * 删除
 * @param params 查询参数
 */
export const systemAccountRemoveApi = (id: string) => api.get(`/admin/systemAdmin/remove/${id}`)

/**
 * 重置密码
 * @param id 查询参数
 */
export const systemAccountResetApi = (id: string) => api.postJ(`/admin/systemAdmin/resetPassWord/${id}`)


/**
 * 查询详情
 * @param params 查询参数
 */
export const systemAccountDetailApi = (id: string) => api.get(`/admin/systemAdmin/detail/${id}`)

/**
 * 用户下拉
 * @returns
 */
export const systemAdminDropDownApi = () => api.get<ISystemAccount[]>("/admin/systemAdmin/dropDown")
