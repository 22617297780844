
import {Component, Vue} from "vue-property-decorator";
import {ISystemAccount, ISystemAccountQuery} from "@/apis/systemManage/account/types";
import {
  systemAccountCreateApi,
  systemAccountDetailApi,
  systemAccountModifyApi,
  systemAccountQueryApi,
  systemAccountRemoveApi,
  systemAccountResetApi
} from "@/apis/systemManage/account";
import {Message} from "element-ui";
import {checkPhone} from '@/constants/validators'
import {ISystemRole} from "@/apis/systemManage/role/types";
import {systemRoleDownListApi} from "@/apis/systemManage/role";
import {ICrudOption} from "@/types/m-ui-crud";
/**
 * @name: 系统管理-账号管理
 * @author: lili
 * @date: 2023-07-19 10:08
 * @description： 系统管理-账号管理
 * @update: 2023-07-19 10:08
 */
@Component({})
export default class UserIndex extends Vue {

  //确认密码 检验
  confirmPasswordRules(rule: any, value: any, callback: any) {
    if (value === "") {
      callback(new Error("请再次输入密码"));
    } else if (value !== this.modelForm.pwd) {
      callback(new Error("两次输入密码不一致!"));
    } else {
      callback();
    }
  }

  queryForm: ISystemAccountQuery = {
    page: 1,
    limit: 10
  }
  total: number = 0

  tableData: ISystemAccount[] = []
  // 角色下拉
  roleList: ISystemRole[] = []

  // 新增修改表单
  modelForm: ISystemAccount = {
    status: 1
  }

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    addTitle: '新增用户',
    editTitle: '编辑用户',
    menu: true,
    editBtn: true,
    delBtn: true,
    dialogWidth: '800px',
    column: [
      {
        label: "ID",
        prop: "id",
        align: 'center',
        width: 200,
        addHide: true,
        editHide: true,
      },
      {
        label: "关键字",
        prop: "antiStop",
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        placeholder: "账号/姓名"
      },
      {
        label: "账号",
        prop: "account",
        align: 'center',
        rules: [{required: true, message: '请输入账号', trigger: 'blur'},],
        span: 12,
        maxlength:"50"
      },
      {
        label: "密码",
        prop: 'pwd',
        type: 'password',
        rules: [
          {
            required: true,
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback(new Error("请输入密码"));
              } else if (!/[a-zA-Z\d]$/.test(value)) {
                callback(new Error("密码格式不正确!"));
              } else {
                callback();
              }
            },

            trigger: 'blur'
          },
        ],
        span: 12,
        hide: true,
        editHide: true,
      },
      {
        label: "姓名",
        prop: "realName",
        align: 'center',
        rules: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        span: 12,
        maxlength:"50"
      },
      {
        label: "手机号",
        prop: "phone",
        align: 'center',
        width: 150,
        span: 12,
        rules: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {trigger: 'blur', validator: checkPhone}
        ],
      },

      {
        label: "身份",
        prop: "roleName",
        align: "center",
        width: 180,
        addHide: true,
        editHide: true,
      },
      {
        label: "身份",
        prop: "roleIdList",
        align: "center",
        span: 12,
        hide:true,
        type: "select",
        //@ts-ignore
        multiple:true,
        // hide: true,
        dicUrl: '/api/admin/systemRole/pullDownList',
        dicFormatter: (res: any) => ({list: res.data, label: 'roleName', value: 'id'}),
        rules: [
          {required: true, message: '请选择身份', trigger: 'blur'}
        ]
      },
      {
        label: "最后一次登录时间",
        prop: "updateTime",
        align: 'center',
        width:200,
        addHide: true,
        editHide: true
      },

      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        value: 1,
        type: "switch",//状态:1=有效 2=无效
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 12,
        // rules: [
        //   {required: true, message: '请选择状态', trigger: 'blur'}
        // ]
      },
    ]
  }

  /**
   * 重置
   * @param id
   */
  handleRemove(id: string) {
    this.$confirm('是否重置密码?', '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      systemAccountResetApi(id).then(e => {
        if (e) {
          Message.success("重置成功!")
          this.getList()
        }
      })
    }).catch((action ) => {
    });
  }


  getList() {
    systemAccountQueryApi(this.queryForm).then(e => {
      //@ts-ignore
      this.tableData = e.data.list;
      this.total = e.data.total
    })
  }

  getRoleList() {
    systemRoleDownListApi().then(e => {
      return new Promise(resolve => {
        this.roleList = e.data;
        // @ts-ignore
        this.crudOption.column[7]['dicData'] = e.data.map(item => {
          return {
            label: item.roleName,
            value: item.id
          }
        })
        resolve(e.data)
      })
    })
  }

  /**
   * 新增提交
   */
  submitSave(form: any, done: any, loading: any) {
    systemAccountCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改提交
   */
  submitEdit(form: any, done: any, loading: any) {
    systemAccountModifyApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 删除提交
   */
  submitRemove(form: any) {
    systemAccountRemoveApi(form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
      }
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: ISystemAccount, val: number) {
    //@ts-ignore
    systemAccountModifyApi({id: row.id, status: val}).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  async openAdd() {
    this.modelForm.status = 1
    await this.getRoleList()
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }
  /**
   * 打开编辑
   * @param row
   */
  async openEdit(row: ISystemAccount, $index: number) {
    await this.getRoleList()
    systemAccountDetailApi(row.id as string).then(e => {
      let row = e.data;
      row.roleIdList = row.roleIdList || [];

      // @ts-ignore
      this.$refs.crudRef.rowEdit(row, $index)
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn('roleIdList', {
        dicData: this.roleList.map((e) => {
          return {
            label: e.roleName,
            value: e.id+''
          }
        })||[]
      })
    })
  }

  created() {
    this.getList();
  }
}
